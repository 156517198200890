import React, { useEffect, useState } from "react";
import {
  Fab,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Slide from "@material-ui/core/Slide";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
// import NewWindow from "react-new-window";
import instaconnect from "@amanisystemsinc/instaconnect";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const OptionsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#F4A3A3",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const { AllinOne, TextChat } = instaconnect();
// const API_URL = "http://localhost:8000/api/";
const API_URL = "https://instaconnect.carebaseone.com/api/api/";
const getAllUser = (accessToken) => {
  return axios.get(API_URL + "users", {
    headers: {
      "x-access-token": accessToken,
    },
  });
};

const sendVoicemail = (userId, voicemail) => {
  return axios.post(API_URL + "voicemail/" + userId, {
    voicemail: voicemail,
  });
};

const InstaconnectPopUp = ({ roomId, textchat = false, audioOnly }) => {
  // let { roomId } = useParams();

  const handleSend = (voicemail) => {
    sendVoicemail(roomId, voicemail)
      .then((response) => {
        if (!response) throw "Cannot send message!!!";
        console.log("successfully sent--->", response);
        return response;
      })
      .catch((e) => {
        console.log("Error", e);
        throw e;
      });
  };

  return (
    <div>
      {textchat ? (
        <TextChat roomId={roomId + "chat"} handleSend={handleSend} />
      ) : (
        <AllinOne
          room_id={roomId}
          activeRecordButton={false}
          handleSend={handleSend}
          audioOnly={audioOnly}
        />
      )}
      ;
    </div>
  );
};

const DeptwiseTable = ({
  textchat = false,
  audioOnly,
  users,
  style = { variant: "outlined", color: "primary" },
}) => {
  const [roomId, setRoomId] = useState("");
  const [displayRoom, setDisplayRoom] = useState(false);

  const departments = [...new Set(users.map((user) => user.department))];

  return (
    <>
      {!displayRoom && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Department</TableCell>
              <TableCell>Instaconnect</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department) => {
              const user = users.filter(
                (user) =>
                  user.department === department && user.status === "ONLINE"
              )[0];
              return (
                <TableRow key={department}>
                  <TableCell>{department}</TableCell>
                  <TableCell>
                    {/* <Link to={`/instaconnect/${user._id}`}> */}
                    <Button
                      onClick={() => {
                        if (user) {
                          setRoomId(user._id);
                          setDisplayRoom(true);
                        }
                      }}
                      {...style}
                      disabled={!user}
                    >
                      connect
                    </Button>
                    {/* </Link> */}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {displayRoom && (
        <InstaconnectPopUp
          roomId={roomId}
          textchat={textchat}
          audioOnly={audioOnly}
        />
      )}
    </>
  );
};

const UserTable = ({
  textchat = false,
  audioOnly,
  users,
  style = { variant: "outlined", color: "primary" },
}) => {
  const [roomId, setRoomId] = useState("");
  const [displayRoom, setDisplayRoom] = useState(false);
  return (
    <>
      {!displayRoom && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Department</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Instaconnect</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.firstName + " " + user.lastName}</TableCell>
                <TableCell>{user.department}</TableCell>
                <TableCell>{user.status}</TableCell>
                <TableCell>
                  {/* <Link to={`/instaconnect/${user._id}`}> */}
                  <Button
                    onClick={() => {
                      setRoomId(user._id);
                      setDisplayRoom(true);
                    }}
                    {...style}
                    disabled={user.status !== "ONLINE"}
                  >
                    connect
                  </Button>
                  {/* </Link> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {displayRoom && (
        <InstaconnectPopUp
          roomId={roomId}
          textchat={textchat}
          audioOnly={audioOnly}
        />
      )}
    </>
  );
};

const PopUpUserTable = ({
  users,
  deptwise,
  textchat = false,
  audioOnly,
  open = false,
  handleClose,
}) => {
  const classes = useStyles();
  const tirgger = useScrollTrigger({ target: window });
  // const [open, setOpen] = useState(false);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <CssBaseline />
      <Slide appear={false} direction="down" in={!tirgger}>
        <AppBar className={classes.appBar} color="transparent">
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Instaconnect
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
      </Slide>
      {deptwise ? (
        <DeptwiseTable
          users={users}
          textchat={textchat}
          audioOnly={audioOnly}
        />
      ) : (
        <UserTable users={users} textchat={textchat} audioOnly={audioOnly} />
      )}
    </Dialog>
  );
};

const InstaWidget = ({
  buttonText = "Contact Us",
  accessToken,
  deptwise = false,
}) => {
  const [users, setUsers] = useState([]);
  const [videoPopUp, setVideoPopUp] = useState(false);
  const [voicePopUp, setVoicePopUp] = useState(false);
  const [textPopUp, setTextPopUp] = useState(false);
  useEffect(() => {
    getAllUser(accessToken)
      .then((user) => {
        console.log("data===>", user.data);
        setUsers(user.data);
      })
      .catch((e) => {
        console.log("Something went wrong while fetching users===>", e);
        window.alert(`Ooops!!! ${e.message}`);
      });
  }, []);
  return (
    <div>
      <OptionsTooltip
        placement="top-start"
        interactive
        title={
          <div>
            <Button onClick={() => setTextPopUp((prev) => !prev)}>Chat</Button>
            <br />
            <Button onClick={() => setVoicePopUp((prev) => !prev)}>
              Voice Call
            </Button>
            <br />
            <Button onClick={() => setVideoPopUp((prev) => !prev)}>
              Video Call
            </Button>
          </div>
        }
      >
        <Fab
          variant="extended"
          color="secondary"
          size="large"
          style={{ position: "fixed", right: "0.5rem", bottom: "0.5rem" }}
        >
          {buttonText}
        </Fab>
      </OptionsTooltip>

      {videoPopUp && (
        <PopUpUserTable
          users={users}
          deptwise={deptwise}
          textchat={false}
          open={videoPopUp}
          handleClose={() => setVideoPopUp(false)}
        />
      )}
      {voicePopUp && (
        <PopUpUserTable
          users={users}
          deptwise={deptwise}
          textchat={false}
          audioOnly={true}
          open={voicePopUp}
          handleClose={() => setVoicePopUp(false)}
        />
      )}
      {textPopUp && (
        <PopUpUserTable
          users={users}
          deptwise={deptwise}
          textchat={true}
          open={textPopUp}
          handleClose={() => setTextPopUp(false)}
        />
      )}
    </div>
  );
};

export default InstaWidget;
